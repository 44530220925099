import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { NgModule } from '@angular/core';
import { MultivalueFilterComponent } from './filters/multivalue-filter/multivalue-filter.component';
import { TableHeaderMenuComponent } from './menus/header/header-menu.component';
import { ColumnDefinitionsPipe } from './pipes/column-definitions.pipe';
import { HeaderDefinitionsPipe } from './pipes/header-definitions.pipe';
import { EntityCellRendererComponent } from './renderers/entity/entity-renderer.component';
import { IconCellRendererComponent } from './renderers/icon/icon-renderer.component';
import { ThumbnailCellRendererComponent } from './renderers/thumbnail/thumbnail-renderer.component';
import { TrendCellRendererComponent } from './renderers/trend/trend-renderer.component';
import { TableComponent } from './table.component';
import { LinkCellRendererComponent } from './renderers/link/link-renderer.component';

ModuleRegistry.registerModules([ClientSideRowModelModule, InfiniteRowModelModule]);
@NgModule({
  imports: [
    TableComponent,
    TrendCellRendererComponent,
    IconCellRendererComponent,
    ThumbnailCellRendererComponent,
    EntityCellRendererComponent,
    LinkCellRendererComponent,
    MultivalueFilterComponent,
    TableHeaderMenuComponent,
    ColumnDefinitionsPipe,
    HeaderDefinitionsPipe,
  ],
  exports: [
    TableComponent,
  ],
})
export class TableModule { }
