<div class="lyrics-editor-wrapper padded-content flex-column flex-fill" *ngIf="isOpen">
  <h3 *ngIf="title || showCloseButton">
    <button mat-icon-button *ngIf="showCloseButton" (click)="close()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    {{title}}
  </h3>
  <div class="flex-auto">
    <mat-form-field appearance="fill">
      <mat-label>Lyrics</mat-label>
      <textarea matInput placeholder="Track lyrics..." [(ngModel)]="lyrics" (ngModelChange)="onLyricsChange($event)">
    </textarea>
    </mat-form-field>
  </div>
</div>
