import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Optional, Output, ViewChild } from '@angular/core';
import { FormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BehaviorSubject, Observable } from 'rxjs';
import { FieldValue } from '../../_models';
import { BaseComponent, EDIT_CONTEXT, EditContextType, I18N_CONTEXT } from '../../common';

@Component({
  selector: 'hdis-lyrics-editor',
  templateUrl: './lyrics-editor.component.html',
  styleUrls: ['./lyrics-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule],
})
export class LyricsEditorComponent extends BaseComponent {
  @Input() fieldName: string;

  @Input()
  get showCloseButton(): boolean { return this._showCloseButton; }

  set showCloseButton(value) { this._showCloseButton = coerceBooleanProperty(value); }

  _showCloseButton = false;

  @Input() title: string;

  @Output() readonly lyricsChange = new EventEmitter<any>();

  @Output() readonly opened = new EventEmitter<void>();

  @Output() readonly closed = new EventEmitter<void>();

  lyrics: string;

  fieldsLoading: boolean;

  selected$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  available$: BehaviorSubject<FieldValue[]> = new BehaviorSubject([]);

  options$: Observable<{ value: string; visible: boolean }[]>;

  @ViewChild('filterInput') filterInput: ElementRef;

  filter = new UntypedFormControl('');

  showInvalidValues = false;

  isOpen = false;

  isReadonly = false;

  isDisabled = false;

  constructor(
    @Optional() @Inject(I18N_CONTEXT) public i18nContext: string,
    @Optional() @Inject(EDIT_CONTEXT) public editContext: EditContextType,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.showCloseButton = editContext === 'panel';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    console.log('destroy lyrics editor');
    this.closed.emit();
  }

  setLyrics(lyrics: string) {
    this.lyrics = lyrics;
    this.cdr.markForCheck();
  }

  open(fieldName: string, lyrics: string) {
    // first clean any possible dirty state by closing the previous picker
    this.close();

    // then updated the component props
    this.setLyrics(lyrics);
    this.isOpen = true;

    // finally emit the `opened` event to let subscribers (usually the directive) to properly subscribe
    this.opened.emit();
  }

  close() {
    this.isOpen = false;
    this.closed.emit();
  }

  onLyricsChange(newValue: string) {
    this.lyricsChange.emit(newValue);
  }
}
