export enum Icons {
  TRACK = 'audiotrack',
  POOL = 'bubble_chart',
  PROFILE = 'speaker_group',
  BRAND = 'copyright',
  SCHEDULE = 'date_range',
  // SCHEDULE = 'table_chart',
  PLAYLIST = 'queue_music',
  SELECTION = 'format_list_bulleted',
  RADIO_STATION = 'radio',
  COMPILATION = 'menu_book',
  ARTIST = 'record_voice_over',
  PLAYER = 'play_lesson',
  DEVICE = 'scanner',
  LOCATION = 'location_city',

  DASHBOARD = 'dashboard',
  INBOX = 'all_inbox',

  ACTION_OPEN = 'open_in_browser',
  ACTION_LINK = 'link',

  ACTION_CANCEL = 'clear',
  // ACTION_LINK = 'share',

  ACTION_EDIT = 'edit',
  ACTION_CONFIGURE = 'tune',
  ACTION_RESET = 'settings_backup_restore',
  ACTION_SAVE = 'save',
  ACTION_RENAME = 'edit',
  ACTION_DELETE = 'delete',
  ACTION_CLEAN = 'cleaning_services',
  ACTION_ADD = 'add',
  ACTION_REMOVE = 'remove_circle',
  ACTION_RESTORE = 'restore_from_trash',
  ACTION_PERSISTENT_DELETE = 'delete_forever',
  ACTION_CLONE = 'file_copy',
  ACTION_DOWNLOAD = 'cloud_download',
  ACTION_UPLOAD = 'cloud_upload',
  ACTION_SEND_ISP = 'radio',
  ACTION_SEND_PLM = 'cast_connected',
  ACTION_TRAIN = 'precision_manufacturing', // model_training or engineering might fit as well, they are just boring
  ACTION_FILTER = 'filter_list',
  ACTION_ANALYZE = 'pie_chart',
  ACTION_HISTORY = 'alarm',
  ACTION_UNDO = 'undo',
  ACTION_REDO = 'redo',
  ACTION_REFRESH = 'autorenew',
  ACTION_DEBUG = 'bug_report',
  ACTION_MANAGE_PERMISSIONS = 'people',
  ACTION_COPY = 'content_copy',
  ACTION_OPEN_NEW_TAB = 'open_in_new',
  ACTION_SEARCH = 'search',

  ACTION_PLAYER_PLAY = 'playlist_play',
  ACTION_PLAYER_ENQUEUE = 'playlist_add_checked',
  ACTION_PLAYER_APPEND = 'playlist_add',

  ACTION_BOOKMARK_ADD = 'bookmark_border',
  ACTION_BOOKMARK_REMOVE = 'bookmark',

  ACTION_FAVORITE_SET = 'star',
  ACTION_FAVORITE_UNSET = 'star_border',

  ACTION_CREATE = 'post_add',
  ACTION_CORRELATE = 'compare_arrows',
  ACTION_CONVERT = 'local_laundry_service',

  ACTION_PUBLISH = 'verified',
  ACTION_UNPUBLISH = 'backspace',

  ACTION_SHOW = 'visibility',
  ACTION_HIDE = 'visibility_off',
  ACTION_MINIMIZE = 'close_fullscreen',

  ACTION_DRAG = 'drag_indicator',

  ACTION_APPROVE = 'thumb_up',
  ACTION_REJECT = 'thumb_down_off_alt',
  ACTION_CLAIM = 'back_hand',

  ACTION_VALIDATE = 'published_with_changes',

  ACTION_CREATE_LOCATION = 'domain_add',

  STATUS_SUCCESS = 'check_circle',
  STATUS_LOADING = 'hourglass_empty',
  STATUS_ERROR = 'error_outline',

  STATUS_IMPORTED = 'all_inbox',
  STATUS_DRAFT = 'track_changes',
  STATUS_PUBLISHED = 'check_circle',
  STATUS_DELETED = 'highlight_off',
  STATUS_ARCHIVED = 'inventory_2',
  STATUS_PROCESSING = 'engineering',
  STATUS_PROCESSED = 'check_circle_outline',
  STATUS_PENDING = 'pending',

  GENERIC_ERROR = 'error_outline',
  GENERIC_HELP = 'help_outline',

  SERVICE_DISCOGS = 'album',
  SERVICE_LYRICFIND = 'lyrics',
  SERVICE_SPOTIFY = 'wifi',
  SERVICE_AUTOTAGGING = 'precision_manufacturing',
  SERVICE_AWS_COMPREHEND = 'manage_search',
  SERVICE_LOUDNESS = 'troubleshoot',
  SERVICE_SOUNDCHARTS = 'insights',

  EDITOR_EFFECT_FADEIN = 'north_east',
  EDITOR_EFFECT_FADEOUT = 'south_east',
  EDITOR_EFFECT_CUT = 'content_cut',
  EDITOR_ACTION_DRAG = 'drag_handle',
  EDITOR_ACTION_PLAY = 'play_arrow',
  EDITOR_ACTION_REPEAT = 'repeat',
  EDITOR_ACTION_LOCK = 'lock',
  EDITOR_ACTION_REMOVE = 'clear',
}
