import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'hdis-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimepickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TimepickerComponent), multi: true },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, MatAutocompleteModule, MatFormFieldModule, MatInputModule],
})
export class TimepickerComponent implements ControlValueAccessor {
  @Input() timeLabel = 'time';

  @Input()
  get hideTime(): boolean { return this._hideTime; }

  set hideTime(value) { this._hideTime = coerceBooleanProperty(value); }

  _hideTime = false;

  currentTime: string;

  readonly = false;

  propagateFn: (newValue: string) => void;

  writeValue(value: string): void {
    this.currentTime = value;
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  onChange() {
    this.propagateFn(this.currentTime);
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // leave the validation of empty value to the required validator
    if (!control.value) return null;
    // return true when the value of control matches the pattern HH:mm
    if (/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(control.value)) return null;
    return { invalidTime: true };
  }
}
