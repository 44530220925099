import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Icons } from '../../../common';

interface LinkCellRendererParams extends ICellRendererParams {
  urlFn: (cellValue, rowValue) => string;
}

@Component({
  selector: 'hdis-link-renderer',
  templateUrl: './link-renderer.component.html',
  styleUrls: ['./link-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButtonModule, MatIconModule],
})
export class LinkCellRendererComponent implements AgRendererComponent {
  Icons = Icons;

  label: string;

  url: string;

  agInit(params: LinkCellRendererParams): void {
    this.label = params.value;
    this.url = params.value && params.urlFn(params.value, params.data);
  }

  refresh(params: any): boolean {
    return false;
  }

  goToUrl(event: MouseEvent) {
    event.stopPropagation();
    window.open(this.url, '_blank');
  }
}
