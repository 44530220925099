import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { isValidUrl } from '../../common';

@Component({
  standalone: true,
  selector: 'hdis-soundcharts-field',
  templateUrl: './soundcharts-field.component.html',
  styleUrls: ['./soundcharts-field.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SoundchartsFormFieldComponent), multi: true },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
})
export class SoundchartsFormFieldComponent implements OnInit, ControlValueAccessor {
  @Input() label = 'Soundcharts ID';

  @Input() placeholder = 'enter a valid track URL or ID...';

  @Input() required = false;

  initValue: string;

  control: UntypedFormControl = new UntypedFormControl('', this.soundchartsIdValidator());

  propagateFn: (newValue: string) => void;

  writeValue(obj: any): void {
    this.initValue = obj;
    this.control.reset(this.initValue, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control?.disable({ emitEvent: false }) : this.control.enable({ emitEvent: false });
  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe((newValue) => {
      this.propagateFn(newValue);
    });
  }

  soundchartsIdValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) return { forbiddenId: 'Invalid UUID' };
      return null;
    };
  }
}
