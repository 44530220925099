<mat-form-field>
  <mat-label *ngIf="label">{{label}}</mat-label>
  <input matInput [placeholder]="placeholder" [formControl]="control" autocomplete="off" [required]="required"
    name="spotify">
  <a mat-icon-button matSuffix href="https://open.spotify.com/track/{{control.value}}" target="_blank"
    [disabled]="control.invalid || !control.value">
    <mat-icon>launch</mat-icon>
  </a>
  <mat-error *ngIf="control.errors?.['forbiddenUrl']">
    {{ control.errors?.['forbiddenUrl'] }}
  </mat-error>
</mat-form-field>
