import { Injectable } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Audio, AudioStatus, TrackImageAssetType, TrackManualUpdateRequestBody } from '@heardis/api-contracts';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { currentTrackEditApproved } from '../audio-player/store/audio-player.actions';
import { TrackEditorFormService } from '../library/track/track-editor/track-editor-form.service';
import { ChangesStatus, ChangesStatusService } from './changes-status.service';
import { SongService } from './song.service';

@Injectable()
export class SongFormService {
  constructor(
    private songService: SongService,
    private changeStatusService: ChangesStatusService,
    private trackEditorFormService: TrackEditorFormService,
    private store: Store,
  ) { }

  private toForm(track: Audio) {
    const { ids, replayGain } = track.metadata;

    const form = new UntypedFormGroup({
      _id: new UntypedFormControl(track._id),
      rev: new UntypedFormControl(track.rev),
      features: new UntypedFormGroup({
        genre: new UntypedFormControl(track.metadata.genre, Validators.required),
        mainStyle: new UntypedFormControl(track.metadata.mainStyle, Validators.required),
        subStyles: new UntypedFormControl(track.metadata.subStyles),
        instrumentation: new UntypedFormControl(track.metadata.instrumentation),
        vocals: new UntypedFormControl(track.metadata.vocals, Validators.required),
        language: new UntypedFormControl(track.metadata.language),
        lyrics: new UntypedFormControl(track.metadata.lyrics),
        explicit: new UntypedFormControl(track.metadata.explicit),
        brands: new UntypedFormControl(track.metadata.brands),
        bpm: new UntypedFormControl(track.metadata.bpm, Validators.required),
        keyMode: new UntypedFormControl(track.metadata.keyMode),
        intensity: new UntypedFormControl(track.metadata.intensity, Validators.required),
        danceability: new UntypedFormControl(track.metadata.danceability, Validators.required),
        dynamics: new UntypedFormControl(track.metadata.dynamics, Validators.required),
        building: new UntypedFormControl(track.metadata.building),
        timbre: new UntypedFormControl(track.metadata.timbre, Validators.required),
        conventionality: new UntypedFormControl(track.metadata.conventionality, Validators.required),
        prodQuality: new UntypedFormControl(track.metadata.prodQuality, Validators.required),
        expression: new UntypedFormControl(track.metadata.expression, Validators.required),
        moods: new UntypedFormControl(track.metadata.moods, Validators.required),
        culture: new UntypedFormControl(track.metadata.culture),
        timeReference: new UntypedFormControl(String(track.metadata.timeReference || '')),
        comment: new UntypedFormControl(track.metadata.comment),
        style: new UntypedFormControl(track.metadata.style),
        mood: new UntypedFormControl(track.metadata.mood),
        client: new UntypedFormControl(track.metadata.client),
      }),
      release: new UntypedFormGroup({
        title: new UntypedFormControl(track.metadata.title, Validators.required),
        artist: new UntypedFormControl(track.metadata.artist, Validators.required),
        feat: new UntypedFormControl(track.metadata.feat),
        remix: new UntypedFormControl(track.metadata.remix),
        relArtists: new UntypedFormControl(track.metadata.relArtists),
        relTracks: new UntypedFormControl(track.metadata.relTracks),
        album: new UntypedFormControl(track.metadata.album),
        trackNumber: new UntypedFormControl(track.metadata.trackNumber),
        trackTotal: new UntypedFormControl(track.metadata.trackTotal),
        label: new UntypedFormControl(track.metadata.label, Validators.required),
        catNo: new UntypedFormControl(track.metadata.catNo),
        releaseYear: new UntypedFormControl(track.metadata.releaseYear, Validators.required),
        country: new UntypedFormControl(track.metadata.country),
        artistCountryCodes: new UntypedFormControl(track.metadata.artistCountryCodes),
        artistCities: new UntypedFormControl(track.metadata.artistCities),
        publisher: new UntypedFormControl(track.metadata.publisher),
        rights: new UntypedFormControl(track.metadata.rights),
        composers: new UntypedFormControl(track.metadata.composers),
        ids: new UntypedFormGroup({
          isrc: new UntypedFormControl(ids?.isrc),
          iswc: new UntypedFormControl(ids?.iswc),
        }),
        terms: new UntypedFormControl(track.metadata.terms),
        source: new UntypedFormControl(track.metadata.source, Validators.required),
        type: new UntypedFormControl(track.metadata.type, Validators.required),
      }),
      signal: new UntypedFormGroup({
        source: new UntypedFormGroup({
          mount: new UntypedFormControl('s3://production-heardis-music-archive'),
          path: new UntypedFormControl(track.source.path),
          length: new UntypedFormControl(`${format(track.source.length * 1000, 'mm:ss') || 'N/A'}`),
          codec: new UntypedFormControl(track.source.codec),
          sampleRate: new UntypedFormControl(track.source.sampleRate),
          resolution: new UntypedFormControl(track.source.resolution),
        }),
        lowQuality: new UntypedFormControl(track.metadata.lowQuality),
        replayGain: new UntypedFormGroup({
          track: new UntypedFormControl(replayGain?.track),
          trackPeak: new UntypedFormControl(replayGain?.trackPeak),
          album: new UntypedFormControl(replayGain?.album),
          albumPeak: new UntypedFormControl(replayGain?.albumPeak),
        }),
        mono: new UntypedFormControl(track.metadata.mono),
        loudness: new UntypedFormControl(track.metadata.loudness),
      }),
      platforms: new UntypedFormGroup({
        ids: new UntypedFormGroup({
          heardis: new UntypedFormControl(ids?.heardis),
          discogs: new UntypedFormControl(ids?.discogs),
          isrc: new UntypedFormControl(ids?.isrc),
          iswc: new UntypedFormControl(ids?.iswc),
          spotify: new UntypedFormControl(ids?.spotify),
          soundcharts: new UntypedFormControl(ids?.soundcharts),
        }),
      }),
      lyrics: new UntypedFormGroup({
        'ids.lyricfind': new UntypedFormControl(ids?.lyricfind),
      }),
      editor: this.trackEditorFormService.trackToForm(track),
      cover: new UntypedFormControl(null),
    });

    return form;
  }

  /** collect all the enable/disabled rules for the track form to be easily toggled */
  private enableForm(form: UntypedFormGroup) {
    form.enable();

    // readonly values should stay disabled even when the form is enabled
    form.get('_id').disable();
    form.get('rev').disable();
    form.get('platforms.ids.heardis').disable();
    form.get('signal.replayGain').disable();
    form.get('signal.source').disable();

    // once the user defined a beatgrid in the editor it is not possible anymore to edit from the features
    if ((form.get('editor.beatgrid') as FormArray)?.length > 0) form.get('features.bpm').disable();
  }

  /** tiny wrapper to stay consistent with enableForm method */
  private disableForm(form: UntypedFormGroup) {
    form.disable();
  }

  private getDirtyValues(form: UntypedFormGroup): Record<string, any> {
    const dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        dirtyValues[key] = (currentControl instanceof UntypedFormGroup) ? this.getDirtyValues(currentControl) : currentControl.value;
      }
    });

    return dirtyValues;
  }

  private getDirtyFields(changedProps: Record<string, any>): string[] {
    const fields = [];
    Object.entries(changedProps).forEach((prop) => {
      if (prop[1] !== null && typeof prop[1] === 'object' && !Array.isArray(prop[1])) {
        // if (typeof prop[1] === 'object' && !Array.isArray(prop[1])) {
        fields.push(...this.getDirtyFields(prop[1]));
      } else {
        fields.push(prop[0]);
      }
    });
    return fields;
  }

  resetForm = (track: Audio) => {
    const trackForm = this.toForm(track);

    // ensure that forms cant be edited accidentally by not entitled users or after deletion
    if (!this.songService.canEdit(track) || track.status === AudioStatus.DELETED) {
      this.disableForm(trackForm);
    } else {
      this.enableForm(trackForm);
    }

    trackForm.valueChanges.pipe(take(1)).subscribe((newValues) => {
      this.changeStatusService.setDirty(ChangesStatus.TRACK_INFO, true);
    });
    this.changeStatusService.setDirty(ChangesStatus.TRACK_INFO, false);
    return trackForm;
  };

  submitForm = (trackForm: UntypedFormGroup, changelog: string): Observable<{ form: UntypedFormGroup; track: Audio }> => {
    const changedValues = this.getDirtyValues(trackForm);
    const { features, release, platforms, signal, editor, cover } = changedValues;
    /**
     * another dirty workaround. As the backend is currently not able to merge data, we expect to send the whole edits.
     * However the submitForm so far only sent the changed values. This would result in emptying the edits when editing a single value
     * So when something in the editor part of the form is changed we simply get the whole value and send it
     */
    const newVals: TrackManualUpdateRequestBody = {
      ...((features || release || platforms || signal) ? { manual: { ...features, ...release, ...platforms, ...signal } } : {}),
      ...(editor ? { editor: (trackForm.get('editor') as UntypedFormGroup).getRawValue() } : {}),
      ...(cover ? { cover: { role: TrackImageAssetType.COVER, path: cover } } as TrackManualUpdateRequestBody : {}),
      changelog: changelog || `changed ${this.getDirtyFields(changedValues).join(', ')}`,
    };

    // prevent the user to do changes after submitting the track resulting in a confusing UX
    this.disableForm(trackForm);
    return this.songService.partialUpdateEntity(trackForm.get('_id').value, trackForm.get('rev').value, newVals).pipe(
      map((track: Audio) => {
        const form = this.resetForm(track);
        return { track, form };
      }),
      catchError((err) => {
        this.enableForm(trackForm);
        throw err;
      }),
    );
  };

  approveEdit = (trackForm: UntypedFormGroup, editId: string) => {
    this.disableForm(trackForm);
    return this.songService.publishEdit(trackForm.get('_id').value, editId).pipe(
      map((track: Audio) => {
        const form = this.resetForm(track);
        this.store.dispatch(currentTrackEditApproved());
        return { track, form };
      }),
      catchError((err) => {
        this.enableForm(trackForm);
        throw err;
      }),
    );
  };

  approveLyrics = (trackForm: UntypedFormGroup, lyrics: string) => {
    trackForm.get('features.lyrics').patchValue(lyrics);
    trackForm.get('features.lyrics').markAsDirty();
  };
}
