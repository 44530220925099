<mat-form-field>
  <mat-label *ngIf="label">{{label}}</mat-label>
  <input matInput [placeholder]="placeholder" [formControl]="control" autocomplete="off" [required]="required" name="soundcharts">
  <a mat-icon-button matSuffix href="https://app.soundcharts.com/app/song/{{control.value}}/overview\" target="_blank" [disabled]="control.invalid || !control.value">
    <mat-icon>launch</mat-icon>
  </a>
  <mat-error *ngIf="control.errors?.['forbiddenId']">
    {{ control.errors?.['forbiddenId'] }}
  </mat-error>
</mat-form-field>
